const BASE_URL = `${process.env.BASE_SERVER}/user`;

function getTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

class UserServiceBase {
    register(firstName, lastName, email, password, joinAsMentor) {
        return fetch(`${BASE_URL}/auth/register`, {
            method: 'POST',
            body: JSON.stringify({
                firstName,
                lastName,
                email,
                password,
                timeZone: getTimeZone(),
                availableAsMentor: Boolean(joinAsMentor || false),
            }),
        })
        .then(res => checkFetchStatus(res))
        .then(res => res.json());
    }

    login(email, password) {
        return fetch(`${BASE_URL}/auth/email`, {
            method: 'POST',
            body:  JSON.stringify({ email, password })
        })
        .then(res => checkFetchStatus(res))
        .then(res => res.json());
    }

    loginProvider(provider, profile, joinAsMentor) {
        return fetch(`${BASE_URL}/auth/${String(provider)}`, {
            method: 'POST',
            body: JSON.stringify({
                id: profile.id,
                firstName: profile.firstName || '',
                lastName: profile.firstName || '',
                email: profile.email || '',
                avatar: profile.avatar || '',
                timeZone: getTimeZone(),
                availableAsMentor: joinAsMentor || false,
            }),
        }, false).then(res => checkFetchStatus(res))
        .then(res => res.json());;
    }

    getMe() {
        const headers = new Headers();
        headers.set('X-Authorization', window.localStorage.getItem('sessionId'));
        return fetch(`${BASE_URL}/me`)
            .then(res => checkFetchStatus(res))
            .then(res => res.json());
    }

    recoverPassword(email) {
        return fetch(`${BASE_URL}/reset-password`, {
            method: 'POST',
            body: JSON.stringify({ email })
        }).then(res => checkFetchStatus(res))
        .then(res => res.json());;
    }
}

export const UserService = new UserServiceBase();

function checkFetchStatus(res) {
    return new Promise((resolve, reject) => {
        if (res.status >= 200 && res.status < 300) {
            return resolve(res);
        } else {
            if (res.text) {
                return res.text()
                    .then(errorMessage => {
                        let errObj;
                        try {
                            errObj = JSON.parse(errorMessage);
                        } catch (e) {
                            reject(new Error(errorMessage));
                            return;
                        }
                        reject(errObj);

                    }).catch(e => reject(e));
            } else {
                reject(new Error(`Request failed: ${res.status} - ${res.statusText}`));
            }
        }
    });
}
