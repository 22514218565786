import { BaseComponent } from '../../atoms/BaseComponent';
import {
    closeIcon, fbIcon, twitterIcon,
    backArrowIcon, linkedinIcon, profileIcon,
    emailIcon, emailSentIcon, emailSolidIcon,
    passwordIcon, visibilityPasswordIcon,
} from '../../atoms/Icons';
import EmailValidator from 'email-validator';
import { UserService } from '../../services/UserService';
const PRODUCT_URL = process.env.PRODUCT_URL;

const LoginPopupTypes = {
    MAIN: "login-main-form",
    LOGIN_EMAIL: "login-email-login-form",
    RECOVER_PASSWORD: "login-recover-password-form",
    RECOVER_SUCCESSFUL: "login-recover-successfull-form",
    REGISTER: "login-register-form"
}

const INVALID_INPUT = "___base-input__container_invalid";
const INPUT_HAS_ERROR = "___base-input__container_has-error";
const INPUT_ERROR_TEXT = "___base-input__error-text";

function checkEmail(v) {
    if (!v.trim().length) {
        return 'Required field';
    }

    if (!EmailValidator.validate(v)) {
        return 'Invalid email address';
    }

    return '';
}

function validateForm(view, values) {
    const res = [];
    switch (view) {
        case LoginPopupTypes.LOGIN_EMAIL: {
            const err = checkEmail(values.email);
            if (err) {
                res.push({ name: 'email', message: err });
            }
            break;
        }

        case LoginPopupTypes.REGISTER: {
            let err = checkEmail(values.email);
            if (err) {
                res.push({ name: 'email', message: err });
            }

            err = '';
            if (!values.password.trim().length) {
                err = 'Required field';
            } else if (values.password.trim().length < 5) {
                err = '5 characters at least';
            }

            if (err) {
                res.push({ name: 'password', message: err });
            }

            err = '';

            if (!values.firstName.trim().length) {
                err = 'Required field';
            }

            if (err) {
                res.push({ name: 'firstName', message: err });
            }

            err = '';

            if (!values.lastName.trim().length) {
                err = 'Required field';
            }

            if (err) {
                res.push({ name: 'lastName', message: err });
            }

            break;
        }

        case LoginPopupTypes.RECOVER_PASSWORD: {
            const err = checkEmail(values.email);
            if (err) {
                res.push({ name: 'email', message: err });
            }
            break;
        }
    }

    return res;
}

function initHeaderEvents() {
    document.querySelector('#login-popup-back-button').addEventListener('click', () => {
        const v = this.getPopupView();
        if (!v) { return; }
        this.dispatchEvent(new CustomEvent("change-view", {
            detail: {
                view: v,
                ignoreHistory: true
            }
        }));
    });

    document.querySelector('#login-close-back-button').addEventListener('click', () => {
        this.dispatchEvent(new CustomEvent("close-popup"));
    });
}

function input(placeholder, iconFn, inputId, inputType) {
    return `
        <div class = "___base-input__container ${iconFn ? ' ___base-input__container_with-icon' : ''}">
            <input
                id = "${inputId}"
                class = "___base-input__input"
                placeholder = "${placeholder || ""}"
                type = "${inputType || 'text'}"
            />
            ${
                iconFn ? `<div class = "___base-input__icon">${iconFn()}</div>` : ''
            }
            <div class = "___base-input__error-text">
            </div>
        </div>
    `
}

function inputPassword(iconFn) {
    return `
        <div class = "___base-input__container ${iconFn ? ' ___base-input__container_with-icon' : ''}">
            <input
                type = "password"
                class = "___base-input__input"
                id = "login-popup-password"
                placeholder = "&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
            />
            ${
                iconFn ? `<div class = "___base-input__icon" id = "login-popup-password-icon">${iconFn()}</div>` : ''
            }
            <div class = "___base-input__error-text">
            </div>
        </div>
    `
}

function header(caption) {
    return `
        <div class = "login-popup__header">
            <div id = "login-popup-back-button" class = "login-popup__header-button">
                ${backArrowIcon()}
            </div>
            <div class = "login-popup__header-text">
                ${caption}
            </div>
            <div id = "login-close-back-button" class = "login-popup__header-button">
                ${closeIcon()}
            </div>
        </div>
    `
}

function textButton(caption, className, id) {
    return `
        <button class = "___base-text-button ${className || ""}" id = "${id || ""}">
            ${caption}
        </button>
    `
}

function buttonWithIcon(caption, iconFn, color, className, id) {
    return `
        <button class = "___base-button  ___base-button_color_${color || "primary"}  ___base-button_with-icon  ${className || ""}" id = "${id || ""}">
            <span class = "___base-button__caption lg-Body2 sm-Body2">${caption}</span>
            ${iconFn("___base-button__icon")}
        </button>
    `
}

function button(caption, color, className, id) {
    return `
        <button class = "___base-button ___base-button_color_${color || "primary"} ${className || ""}" id = "${id || ""}">
            <span class = "___base-button__caption lg-Body2 sm-Body2">${caption}</span>
        </button>
    `
}

function buttonIconOnly(iconFn, color, className, id) {
    return `
        <button class = "___base-button ___base-button_color_${color || "primary"} ${className || ""}" id = "${id || ""}">
            ${iconFn("___base-button__icon")}
        </button>
    `
}

function onFacebookClick(joinAsMentor) {
    const fb = window.FB;
    fb.login((response) => {
        if (!response.authResponse) { return; }
        fb.api('/me?fields=id,name,email,first_name,last_name', (profile) => {
            if (!profile.id) { return; }
            UserService.loginProvider('facebook', {
                id: profile.id,
                firstName: profile.first_name,
                lastName: profile.last_name,
                email: profile.email
            }, joinAsMentor).then((r) => {
                const arr = [`sid=${r.sessionId}`];
                if (window.__clientId) {
                    arr.push(`clientId=${window.__clientId}`);
                }
                window.location.replace(`${PRODUCT_URL}?${arr.join('&')}`);
            })

        }, {
            scope: 'public_profile,email',
            return_scopes: true
        });
    });
}

class LoginPopup extends BaseComponent {
    render() {
        this.innerHTML = `
            <div class = "login-popup" style = "display: ${this.state.visible ? 'flex' : 'none'}">
                <div class = "login-popup__container">
                    <${this.state.view} id = "currentLoginForm" join-as-mentor = "${Boolean(this.state.joinAsMentor)}">
                    </${this.state.view}
                </div>
            </div>
        `
        this.initEvents();
    }

    setState(ns) {
        this.state = Object.assign({}, this.state, ns);
        this.render();
    }

    trackView(view) {
        if (window.ga) {
            ga('send', 'event', 'login-popup', 'Impression', `view--${view}`, {
                nonInteraction: true
            });
        }
    }

    initEvents() {
        const el = this.querySelector('#currentLoginForm');
        el.getPopupView = () => this.state.viewHistory.pop();
        el.addEventListener('change-view', (e) => {
            if (e.detail.ignoreHistory) {
                this.setState({
                    view: e.detail.view
                })
            } else {
                this.setState({
                    view: e.detail.view,
                    viewHistory: [...this.state.viewHistory, this.state.view]
                })
                console.log(this.state.viewHistory)
            }
            this.trackView(e.detail.view);
        })

        el.addEventListener('close-popup', (e) => {
            ga('send', 'event', 'login-popup', 'Click', `close-popup`);
            this.hide();
        })
    }

    connectedCallback() {
        this.state = {
            view: LoginPopupTypes.MAIN,
            viewHistory: [LoginPopupTypes.MAIN],
            visible: false,
            joinAsMentor: false
        };

        this.render();
    }

    hide() {
        this.state.visible = false;
        this.querySelector('.login-popup').style.display = 'none';
        this.setState({
            view: LoginPopupTypes.MAIN,
            viewHistory: [LoginPopupTypes.MAIN],
            visible: false
        })
    }

    show(joinAsMentor, form) {
        if (form) {
            this.setState({
                view: form
            });
        }

        this.state.joinAsMentor = joinAsMentor;
        this.state.visible = true;

        this.trackView(form || LoginPopupTypes.MAIN);
        this.querySelector('.login-popup').style.display = 'flex';
    }
}

class LoginMainForm extends BaseComponent {
    render() {
        this.innerHTML = `
            <div class = "login-popup__form main-login-form__form">
                ${closeIcon("main-login-form__closeIcon", "main-login-form__closeIcon")}
                <div class = "main-login-form__bigCaption lg-Header sm-Header">
                    Enter
                </div>
                <div class = "main-login-form__instructions lg-Body2 sm-Body2">
                    through social networks, <br/>
                    or ${textButton('sign up', '', 'email-login-form__signup')} via email:
                </div>
                <div class = "main-login-form__buttons">
                    ${buttonWithIcon("Facebook", fbIcon, "", "", "main-facebook-btn")}
                    ${buttonWithIcon("Email", emailSolidIcon, "dark", '', 'main-login__email')}
                </div>
                <div class = "main-login-form__policies lg-Caption1 sm-Caption1">
                    By creating an account, I agree to the <a class = "___base-link" href = "/terms.html" target = "_blank">Terms of Use</a>, <a class = "___base-link" href = "/privacy.html" target = "_blank">Privacy Policy</a>, and <a class = "___base-link"  href = "/privacy.html" target = "_blank">Cookie Policy</a>.
                </div>
            </div>
        `

        this.initEvents();
    }

    initEvents() {
        const emailButton = document.querySelector('#main-login__email');

        emailButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.LOGIN_EMAIL
                }
            }));
        });

        const signUpButton = document.querySelector('#email-login-form__signup');
        signUpButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.REGISTER
                }
            }));
        });

        const closeIcon = document.querySelector('#main-login-form__closeIcon');
        closeIcon.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('close-popup'));
        })

        const fbBtn = document.querySelector('#main-facebook-btn');
        fbBtn.addEventListener('click', () => {
            onFacebookClick(this.getAttribute('join-as-mentor') === "true");
        })
    }

    connectedCallback() {
        this.render();
    }
}

customElements.define('login-main-form', LoginMainForm);

class LoginEmailLoginForm extends BaseComponent {
    render() {
        this.innerHTML = `
            ${header("Log in via email")}
            <div class = "login-popup__form email-login-form__form">
                ${input("simongreen@thecoachcamp.com", emailIcon, 'login-email__email-input', 'email')}
                ${inputPassword(passwordIcon)}
                <div class = "login-popup__error-place">
                </div>
                ${button("Log in", "primary", "", "login-email__login-button")}
                ${textButton("Forgot your password?", "email-login-form__forgot-password", "email-login-form__forgot-password")}
                <div class = "email-login-form__dont-have-profile">
                    <div>
                        Don't have a profile?
                    </div>
                    ${textButton("Sign up", "", "email-login-form__sign-up")}
                </div>
            </div>
        `

        this.initEvents();
    }

    initEvents() {
        initHeaderEvents.bind(this)();
        const forgetButton = document.querySelector('#email-login-form__forgot-password');
        forgetButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.RECOVER_PASSWORD
                }
            }));
        });

        const loginButton = document.querySelector('#login-email__login-button');
        loginButton.addEventListener('click', () => {
            const inp = this.querySelector('#login-email__email-input');
            const pasInput = this.querySelector('#login-popup-password');

            const errors = validateForm(LoginPopupTypes.LOGIN_EMAIL, {
                email: inp.value
            });

            if (errors.length) {
                console.log(errors);
                inp.parentElement.classList.add(INVALID_INPUT);

                this.querySelector('.login-popup__error-place').innerText = errors[0].message;
                return;
            }

            [inp, pasInput].forEach(i => {
                i.parentElement.classList.remove(INPUT_HAS_ERROR);
                i.parentElement.classList.remove(INVALID_INPUT);
                i.parentElement.classList.remove(INPUT_HAS_ERROR);

                i.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = "";
            })

            this.querySelector('.login-popup__error-place').innerText = "";

            UserService.login(
                inp.value, pasInput.value
            ).then((r) => {
                const arr = [`sid=${r.sessionId}`];
                if (window.__clientId) {
                    arr.push(`clientId=${window.__clientId}`);
                }
                window.location.replace(`${PRODUCT_URL}?${arr.join('&')}}`);
            }).catch(() => {
                inp.parentElement.classList.add(INPUT_HAS_ERROR);
                inp.parentElement.classList.add(INVALID_INPUT);
                inp.parentElement.classList.add(INPUT_HAS_ERROR);

                inp.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = "Invalid email";

                pasInput.parentElement.classList.add(INPUT_HAS_ERROR);
                pasInput.parentElement.classList.add(INVALID_INPUT);
                pasInput.parentElement.classList.add(INPUT_HAS_ERROR);

                pasInput.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = "Invalid password";
            })
        })


        const signUp = document.querySelector('#email-login-form__sign-up');
        signUp.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.REGISTER
                }
            }));
        });

        const passwordIcon = document.querySelector('#login-popup-password-icon');
        passwordIcon.addEventListener('click', () => {
            const it = document.querySelector('#login-popup-password');
            if (it.type === "password") {
                it.type = "text";
                passwordIcon.innerHTML = visibilityPasswordIcon();
            } else {
                it.type = "password";
                passwordIcon.innerHTML = passwordIcon();
            }
        });
    }

    connectedCallback() {
        this.render();
    }
}

customElements.define(LoginPopupTypes.LOGIN_EMAIL, LoginEmailLoginForm);


class LoginRecoverPasswordForm extends BaseComponent {
    render() {
        this.innerHTML = `
            ${header("Password recovery")}
            <div class = "login-popup__form">
                <div class = "login-recovery-form__instructions">
                    Please specify the email address you used to log in to the service:
                </div>
                ${input("simongreen@thecoachcamp.com", emailIcon, "recover-email-input", "email")}
                <div class = "login-popup__error-place">
                </div>
                ${button("Recover", "primary", "", "email-login-form__recover")}
                <div class = "email-login-form__dont-have-profile">
                    <div>
                        Don't have a profile?
                    </div>
                    ${textButton("Sign up", "", "email-login-form__signup")}
                </div>
            </div>
        `

        this.initEvents();
    }

    initEvents() {
        initHeaderEvents.bind(this)();

        const signUpButton = document.querySelector('#email-login-form__signup');
        signUpButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.REGISTER
                }
            }));
        });

        const recoverButton = document.querySelector('#email-login-form__recover');
        recoverButton.addEventListener('click', () => {
            const inp = this.querySelector('#recover-email-input');
            const errors = validateForm(LoginPopupTypes.RECOVER_PASSWORD, {
                email: inp.value
            });

            if (errors.length) {
                console.log(errors);
                inp.parentElement.classList.add(INVALID_INPUT);

                this.querySelector('.login-popup__error-place').innerText = errors[0].message;
                return;
            }

            inp.parentElement.classList.remove(INVALID_INPUT);

            this.querySelector('.login-popup__error-place').innerText = "";
            UserService.recoverPassword(inp.value)
                .then(() => {
                    this.dispatchEvent(new CustomEvent('change-view', {
                        detail: {
                            view: LoginPopupTypes.RECOVER_SUCCESSFUL
                        }
                    }));
                })
                .catch((e) => {
                    if (e.error && e.error.indexOf('No such user') !== -1) {
                        this.querySelector('.login-popup__error-place').innerText = 'Email ID is not registered';
                        return;
                    }

                    this.querySelector('.login-popup__error-place').innerText = 'Can\'t reset password for given email';
                    inp.parentElement.classList.add(INVALID_INPUT);
                })

        });
    }

    connectedCallback() {
        this.render();
    }
}

customElements.define(LoginPopupTypes.RECOVER_PASSWORD, LoginRecoverPasswordForm);

class LoginRecoverPasswordSuccessfulForm extends BaseComponent {
    render() {
        this.innerHTML = `
            ${header("Password recovery")}
            <div class = "login-popup__form">
                <div class = "login-recovery-successful-form_container">
                    ${emailSentIcon()}
                    <div class = "login-recovery-successful-form_instructions">
                        We've just sent you an email to reset your password.
                    </div>
                </div>

                ${button("Back to log in", "primary", '', 'login-form-back-to-login')}
            </div>
        `

        this.initEvents();
    }

    initEvents() {
        initHeaderEvents.bind(this)();
        const emailButton = document.querySelector('#login-form-back-to-login');
        emailButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.LOGIN_EMAIL
                }
            }));
        })
    }

    connectedCallback() {
        this.render();
    }
}

customElements.define(LoginPopupTypes.RECOVER_SUCCESSFUL, LoginRecoverPasswordSuccessfulForm);

class LoginRegisterForm extends BaseComponent {
    render() {
        this.innerHTML = `
            ${header("Sign up")}
            <div class = "login-popup__form login-register-form__form">
                ${input("First Name", profileIcon, 'login-popup__firstName')}
                ${input("Last Name", null, 'login-popup__lastName')}
                ${input("simongreen@thecoachcamp.com", emailIcon, 'login-popup__email', "email")}
                ${inputPassword(passwordIcon)}

                <div class = "login-popup__error-place">
                </div>

                ${button("Sign up", "primary", "", "login-popup__signup")}
                <div class = "email-login-form__dont-have-profile">
                    <div>
                        Already have an account
                    </div>
                    ${textButton("Sign in", "", "login-popup__signin")}
                </div>

                <div class = "login-register-form__social-buttons">
                    <div></div>
                    ${buttonIconOnly(fbIcon, '', 'login-register-form__social-button', 'fb-login-btn')}
                    <div></div>
                </div>
            </div>
        `

        this.initEvents();
    }

    // ${buttonIconOnly(fbIcon, '', 'login-register-form__social-button')}
    // ${buttonIconOnly(twitterIcon, '', 'login-register-form__social-button')}
    // ${buttonIconOnly(linkedinIcon, '', 'login-register-form__social-button')}

    initEvents() {
        initHeaderEvents.bind(this)();
        const signInButton = this.querySelector('#login-popup__signin');
        signInButton.addEventListener('click', () => {
            this.dispatchEvent(new CustomEvent('change-view', {
                detail: {
                    view: LoginPopupTypes.LOGIN_EMAIL
                }
            }));
        });

        const passwordIcon = this.querySelector('#login-popup-password-icon');
        passwordIcon.addEventListener('click', () => {
            const it = this.querySelector('#login-popup-password');
            if (it.type === "password") {
                it.type = "text";
                passwordIcon.innerHTML = visibilityPasswordIcon();
            } else {
                it.type = "password";
                passwordIcon.innerHTML = passwordIcon();
            }
        });

        const loginFBBtn = this.querySelector('#fb-login-btn')

        loginFBBtn.addEventListener('click', () => {
            onFacebookClick(this.getAttribute('join-as-mentor') === "true");
        })

        const signup = this.querySelector("#login-popup__signup");
        signup.addEventListener('click', () => {
            const fnInput = this.querySelector('#login-popup__firstName');
            const lnInput = this.querySelector('#login-popup__lastName');
            const emInput = this.querySelector('#login-popup__email');
            const pasInput = this.querySelector('#login-popup-password');
            const errors = validateForm(LoginPopupTypes.REGISTER, {
                firstName: fnInput.value,
                lastName: lnInput.value,
                email: emInput.value,
                password: pasInput.value
            });

            const getError = (field) => errors.find(e => e.name == field);
            [fnInput, lnInput, emInput, pasInput]
                .forEach(i => {
                    i.parentElement.classList.remove(INVALID_INPUT);
                    i.parentElement.classList.remove(INPUT_HAS_ERROR);
                    i.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = "";
                });

            if (errors.length) {
                console.log(errors);
                let err = getError("firstName");
                if (err) {
                    fnInput.parentElement.classList.add(INVALID_INPUT);
                }

                err = getError("lastName");
                if (err) {
                    lnInput.parentElement.classList.add(INVALID_INPUT);
                    lnInput.parentElement.classList.add(INPUT_HAS_ERROR);
                    lnInput.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = err.message;
                }

                err = getError("email");
                if (err) {
                    emInput.parentElement.classList.add(INVALID_INPUT);
                    emInput.parentElement.classList.add(INPUT_HAS_ERROR);
                    emInput.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = err.message;
                }

                err = getError("password");
                if (err) {
                    pasInput.parentElement.classList.add(INVALID_INPUT);
                    pasInput.parentElement.classList.add(INPUT_HAS_ERROR);
                    pasInput.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = err.message;
                }

                return;
            }
            [fnInput, lnInput, emInput, pasInput]
                .forEach(i => {
                    i.parentElement.classList.remove(INVALID_INPUT);
                    i.parentElement.classList.remove(INPUT_HAS_ERROR);
                    i.parentElement.querySelector(`.${INPUT_ERROR_TEXT}`).innerText = "";
                });

            UserService.register(
                fnInput.value, lnInput.value,
                emInput.value, pasInput.value,
                this.getAttribute('join-as-mentor') === "true"
            )
            .then((r) => {
                const arr = [`sid=${r.sessionId}`];
                if (window.__clientId) {
                    arr.push(`clientId=${window.__clientId}`);
                }
                window.location.replace(`${PRODUCT_URL}?${arr.join('&')}`)
            })
            .catch((e) => {
                if (e.error === 'User already exists.') {
                    this.querySelector('.login-popup__error-place').innerText = 'User already exists';
                }
            })
        })
    }

    connectedCallback() {
        this.render();
    }
}

customElements.define(LoginPopupTypes.REGISTER, LoginRegisterForm);

customElements.define('login-popup', LoginPopup);
